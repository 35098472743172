import { ImgHTMLAttributes } from 'react';
//import {SVGAttributes} from 'react';

export function SmashLogo(props: ImgHTMLAttributes<MediaImage>) {
    return <img {...props} src="/img/smash.png" alt="Logo" />;
}

export function FileLogo(props: ImgHTMLAttributes<MediaImage>) {
    return <img {...props} src="/img/file.png" alt="Logo" width="90px" height="40px" />;
}

export function SmashFileLogo(props: ImgHTMLAttributes<MediaImage>) {
    return <img {...props} src="/img/smashfile.png" alt="Logo" />;
}

export default function ApplicationLogo(props: ImgHTMLAttributes<MediaImage>) {
    return <img {...props} src="/img/file.png" alt="Logo" />;
}
